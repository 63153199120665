"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFeatureFlagHandler = void 0;
const launchdarkly_react_client_sdk_1 = require("launchdarkly-react-client-sdk");
const withFlags_1 = require("./withFlags");
function getFeatureFlagHandler() {
    let wrappingFunction = (arg) => arg;
    // If we are in a test environment, we should allow all feature flags by default
    // Unit tests can override the feature flags as they want to test if they are disabled
    try {
        if (process.env.LAUNCH_DARKLY_ENV === 'test') {
            (0, withFlags_1.setTestMode)(true);
        }
    }
    catch (e) {
        // nop, not in test mode
    }
    // this safely creates a function that will handle the LaunchDarklyProvider for everything
    // else down the tree. In the event the LAUNCH_DARKLY_CLIENT_SIDE_ID is not present, which
    // should only be in the case of a developer machine that has not yet set it up,
    // it will log an error to help them debug.
    try {
        const launchDarklyClientSideId = process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID;
        if (launchDarklyClientSideId) {
            // This defaults to an "unknown" user, i.e., they may not have logged in yet.
            // The `Root` component watches for changes on `account` and `isLoggedIn` and will
            // update the LaunchDarkly user with those changes.
            wrappingFunction = (arg) => (0, launchdarkly_react_client_sdk_1.withLDProvider)({
                clientSideID: launchDarklyClientSideId,
                user: {
                    key: 'unknown'
                }
            })((0, launchdarkly_react_client_sdk_1.withLDConsumer)()(arg));
        }
    }
    catch (e) {
        // nop, client side id is not defined, carry on without LaunchDarkly
        // eslint-disable-next-line no-console
        console.log([
            'You are missing the LAUNCH_DARKLY_CLIENT_SIDE_ID. ',
            'If you are doing anything that works with ',
            'feature flags, you will need to set that.'
        ].join());
    }
    return wrappingFunction;
}
exports.getFeatureFlagHandler = getFeatureFlagHandler;
