"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = exports.fontFamilies = exports.spacing = exports.fontSizes = exports.device = void 0;
// device breakpoints
const size = {
    mobileSmall: '320px',
    mobileMedium: '375px',
    mobileLarge: '425px',
    tablet: '700px',
    laptop: '900px',
    laptopLarge: '1100px',
    desktop: '2560px'
};
exports.device = {
    mobileSmall: `(min-width: ${size.mobileSmall})`,
    mobileMedium: `(min-width: ${size.mobileMedium})`,
    mobileLarge: `(min-width: ${size.mobileLarge})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopLarge: `(min-width: ${size.laptopLarge})`,
    desktop: `(min-width: ${size.desktop})`
};
// fonts
exports.fontSizes = {
    tiny: '10px',
    small: '13px',
    base: '15px',
    medium: '16px',
    big: '19px',
    large: '24px',
    extraLarge: '28px',
    huge: '32px'
};
exports.spacing = {
    large: '20px'
};
exports.fontFamilies = {
    primary: "'Cerebri Sans', sans-serif",
    secondary: 'Noto Serif',
    tertiary: 'Montserrat'
};
// Q: why are there 2 colors objects? one here and the other is in
// src/css/includes/config.ts
// color codes
exports.colors = {
    black: '#16151D',
    white: '#FFFFFF',
    blurple: '#5A1CF4',
    blueHighlight: '#2c7be5',
    huron: '#d2ddec',
    sidewalk: '#F9FBFD',
    pavement: '#edf2f9',
    grayDropShadow: '#e4e8ee',
    grayText: '#516F85',
    greenBright: '#61CD5A',
    indigo: '#3E14A8',
    blurpleLight: '#a690f2',
    blurpleDropdown: '#E4DFF9',
    midnight: '#16063E',
    purpleText: '#4F47B4',
    redError: '#E42144',
    redLight: '#E9BDC9',
    thundercloud: '#4C5576',
    yellowLight: '#FFEC98',
    blueLight: '#C4EAFF'
};
