"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
//
// styles
//
const StyledIcon_1 = require("../StyledIcon");
const CloseIcon_1 = require("../CloseIcon");
const StyledDropdown_1 = require("./StyledDropdown");
const StyledDropdownText_1 = require("./StyledDropdownText");
const StyledMenu_1 = require("./StyledMenu");
const MalomoButton_1 = require("../MalomoButton");
const FilterDropdown = (props) => {
    const [showSelections, setShowSelections] = (0, react_1.useState)(false);
    const [selectedOption, setSelectedOption] = (0, react_1.useState)(props.placeholder);
    const wrapperRef = (0, react_1.useRef)(null);
    function detectClick(ref) {
        (0, react_1.useEffect)(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowSelections(false);
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    detectClick(wrapperRef);
    (0, react_1.useEffect)(() => {
        if (props.selection === 'default') {
            setSelectedOption(props.placeholder);
        }
        else {
            setSelectedOption(props.selection);
        }
    }, [props.selection]);
    function handleToggleDropdown() {
        setShowSelections((current) => !current);
    }
    function handleSelectOption(option) {
        setShowSelections(false);
        setSelectedOption(option);
        props.callback(option);
    }
    function handleResetRange(event) {
        event.preventDefault();
        handleSelectOption(props.placeholder);
        setShowSelections(false);
        props.callback('default');
    }
    function renderOptions(options) {
        const optionsArray = [];
        options.map((option) => {
            let optionLabel = option;
            if (option.label) {
                optionLabel = option.label;
            }
            optionsArray.push(react_1.default.createElement(StyledDropdownText_1.StyledDropdownText, { className: 'card-body change-account', key: JSON.stringify(option), onClick: () => handleSelectOption(option), option: true }, optionLabel));
        });
        return (react_1.default.createElement(StyledMenu_1.StyledMenu, { className: `dropdown-menu dropdown-menu-car ${showSelections ? 'show' : ''}` }, optionsArray));
    }
    let label = selectedOption;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'label' does not exist on type 'Option'.
    if (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'label' does not exist on type 'Option'.
        label = selectedOption.label;
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(StyledDropdown_1.StyledDropdown, { className: 'dropdown', 
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            margin: props.margin, ref: wrapperRef },
            react_1.default.createElement(MalomoButton_1.MalomoButton, { "aria-expanded": 'false', "aria-haspopup": 'true', "data-toggle": 'dropdown', onClick: handleToggleDropdown, rounded: true, secondary: true, style: { height: '45px', minWidth: props.wide ? '165px' : '' }, subtle: true, type: 'button', wide: props.wide },
                react_1.default.createElement(StyledDropdownText_1.StyledDropdownText, { id: 'preset-range' }, label),
                selectedOption === props.placeholder ? null : (react_1.default.createElement(CloseIcon_1.CloseIcon, { className: 'fe fe-x', onClick: handleResetRange })),
                react_1.default.createElement(StyledIcon_1.StyledIcon, { className: 'fe fe-chevron-down' })),
            renderOptions(props.options))));
};
exports.default = FilterDropdown;
