"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearUser = exports.setUser = exports.init = void 0;
const Sentry = __importStar(require("@sentry/react"));
const tracing_1 = require("@sentry/tracing");
const hostMatch = (pattern) => !!window.location.host.match(pattern);
const environment = () => {
    switch (true) {
        case hostMatch(/dash\.gomalomo.com/):
            return 'production';
        case hostMatch(/staging\.gomalomo.com/):
            return 'staging';
        default:
            return 'development';
    }
};
const init = () => {
    const env = environment();
    Sentry.init({
        // this is the public API key for all environments, it is safe to hardcode
        dsn: 'https://5e3010b91bee461a9036fcb19a6fc282@o207194.ingest.sentry.io/6433781',
        integrations: [new tracing_1.BrowserTracing()],
        environment: env,
        enabled: ['production', 'staging'].includes(env),
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.2
    });
};
exports.init = init;
const setUser = (id) => {
    Sentry.setUser({ id });
};
exports.setUser = setUser;
const clearUser = () => {
    Sentry.setUser(null);
};
exports.clearUser = clearUser;
