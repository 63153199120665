"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customersCopy = void 0;
exports.customersCopy = {
    loading: 'Loading Customers',
    fetchError: 'The server could not be reached, please try again later',
    noCustomersHeader: 'No customers yet.',
    noCustomersParagraph: 'Once Malomo is connected to your store account, customers will be added automatically.',
    mainHeader: 'Customers',
    column1: 'Name',
    column2: 'Email',
    column3: 'Added On',
    button: 'Orders',
    modalHeader1: 'Order',
    modalHeader2: 'Time | Date',
    modalLoading: 'Loading Orders'
};
