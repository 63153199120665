"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ordersCopy = void 0;
exports.ordersCopy = {
    loading: 'Loading Orders',
    fetchError: 'The server could not be reached, please try again later',
    noOrdersHeader: 'No orders yet.',
    noOrdersParagraph: 'Once Malomo is connected to your store account, orders will be added automatically.',
    mainHeader: 'Orders',
    column1: 'Order',
    column2: 'Name',
    column3: 'Email',
    column4: 'Date',
    button: 'Details',
    modalColumn1: 'Tracking',
    modalColumn2: 'Carrier',
    modalColumn3: 'Status',
    modalColumn4: 'Arrival'
};
