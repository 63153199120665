"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInfiniteScroll = void 0;
const react_1 = require("react");
const lodash_1 = require("lodash");
const useInfiniteScroll = ({ hasNext, loadNext }) => {
    (0, react_1.useEffect)(() => {
        const handleScroll = (0, lodash_1.debounce)(() => {
            const reachedBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
            if (reachedBottom && hasNext) {
                loadNext();
            }
        }, 200);
        const dashboardContainer = document.getElementById('dashboard-container');
        dashboardContainer === null || dashboardContainer === void 0 ? void 0 : dashboardContainer.addEventListener('scroll', handleScroll);
        return () => {
            dashboardContainer === null || dashboardContainer === void 0 ? void 0 : dashboardContainer.removeEventListener('scroll', handleScroll);
        };
    }, [hasNext, loadNext]);
};
exports.useInfiniteScroll = useInfiniteScroll;
